<template>
  <div class="maincontent-list" id="authorityList">
    <div class="main-search">
      <div class="btn-block" @click="addRole">
        <i class="iconfont iconicon-zengjia"></i>新建角色
      </div>
    </div>
    <div class="main-table">
      <el-table
        :data="tableData"
        max-height="750"
        style="width: 100%"
        border
        :header-cell-style="headerStyle"
      >
        <el-table-column prop="ztr_name" label="角色名称"> </el-table-column>
        <el-table-column prop="modifytime" label="上次修改时间">
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <div
              v-if="
                scope.row.ztr_name === '系统管理' ||
                  scope.row.ztr_name === '超级管理员'
              "
            >
              <span></span>
            </div>
            <div v-else>
              <el-button
                @click="editRole(scope.row.ztr_id)"
                type="text"
                size="small"
                >修改</el-button
              >
              <el-button
                type="text"
                size="small"
                @click="remove(scope.row.ztr_id)"
                >删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="main-page" style="margin-top:10px;text-align: center;">
      <Page
        :total="total"
        show-sizer
        show-elevator
        show-total
        :page-size="pageSize"
        :current="pageNum"
        @on-page-size-change="pagesizechange"
        @on-change="pagenumberchange"
      />
    </div>

    <!-- 确认删除弹窗 -->
    <Modal v-model="delectmodal" title="删除" width="440">
      <div style="margin-bottom:17px">
        <i class="iconfont iconicon-gantanhao modalGantanhao"></i>是否确定删除？
      </div>
      <div class="modalDelMessage">
        删除操作不可撤销，删除的内容将不可恢复。
      </div>
      <div slot="footer">
        <Button @click="delectmodal = false" class="white-btn">取消</Button>
        <Button type="primary" @click="deleteConfirm">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      text: "",
      type: null,
      tableData: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      delectId: 0,
      delectmodal: false // 删除弹窗
    };
  },
  methods: {
    // 修改表头的颜色
    headerStyle({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === 0) {
        return (
          "background-color: #f5f5f5;" +
          "height: 40px;" +
          "padding:0px;" +
          "color: #515a6e;" +
          "font-weight: 600;" +
          "font-size: 16px" +
          "font-family: SourceHanSansCN-Regular, SourceHanSansCN"
        );
      }
    },
    addRole() {
      this.$router.push({
        path: "/systemManagement/addRole"
      });
    },
    // 下一步跳转到新建页面
    editRole(id) {
      this.$router.push({
        path: "/systemManagement/addRole",
        query: {
          id: id
        }
      });
    },
    // 修改每页条数
    pagesizechange(pagesize) {
      this.pageSize = pagesize;
      this.pageNum = 1;
      this.getTableList();
    },
    // 修改页码
    pagenumberchange(pagenum) {
      this.pageNum = pagenum;
      this.getTableList();
    },
    // 获取列表
    getTableList() {
      // 参数
      const params = {
        pageNum: this.pageNum, // 当前页码
        pageSize: this.pageSize // 每页显示数量
      };
      this.axios({
        url: "/dtsum/zongtong/organization/RoleManagerController/roleList",
        method: "get",
        params: params
      })
        .then((res) => {
          if (res.data.success === 1) {
            const list = res.data.body.list;
            const result = list.list;
            if (result.length <= 0) {
              this.tableData = [];
            } else {
              this.tableData = result;
            }
            this.total = list.total;
            this.pageNum = list.pageNum;
            this.pageSize = list.pageSize;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 删除
    remove(id) {
      this.delectId = id;
      this.delectmodal = true;
    },
    // 执行删除
    deleteConfirm() {
      const params = {
        roleId: this.delectId
      };
      this.axios({
        url: "/dtsum/zongtong/organization/RoleManagerController/deleteRole",
        method: "get",
        params: params
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("删除成功！");
            this.delectmodal = false;
            this.pageNum = 1;
            this.pageSize = 10;
            this.getTableList();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  created() {
    this.getTableList();
  }
};
</script>

<style lang="scss" scoped>
@function px2rem($px) {
  $rootFontSize: 192px;
  @return ($px / $rootFontSize) + rem;
}
.maincontent-list {
  .main-search {
    margin-bottom: 10px;
    .iconfont {
      margin-right: 5px;
    }
    .addRole {
      color: rgba(99, 115, 129, 1);
      cursor: pointer;
    }
    .addRole:hover {
      color: #2980b9;
    }
    .btn-block {
      height: px2rem(30px);
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      text-align: center;
    }
  }
}
</style>

<style lang="scss">
@media screen and (min-width: 1500px) {
  .maincontent-list {
    .el-table::before {
      height: 0;
    }
  }
}
#authorityList {
    .el-table td, .el-table th{
    padding: 0px!important;
  }
  .el-table--border {
    border-bottom: 1px solid #ebeef5;
  }
  .el-table__body-wrapper {
    max-height: 710px !important;
  }
}
.modalGantanhao {
  color: #FF1414;
}
</style>
